const NegotiationPricingStrategyAnchor = new Map([
  ['CurrentOffer', 'Current Offer'],
  ['COGS', 'COGS'],
  ['Price', 'List Price'],
  ['BestPriceReceivedGlobal', 'Best Price Received (Global)'],
  ['BestPriceReceivedDL', 'Best Price Received (DL)'],
  ['BestCurrentOffer', 'Best Current Offer'],
  ['FlatRate', 'Flat Rate'],
  ['ReservePrice', 'Reserve Price'],
  ['SuggestedPrice', 'Suggested Price'],
  ['Default', 'Default'],
  ['Manual', 'Manual'],
]);

export default NegotiationPricingStrategyAnchor;
