import { Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  offerCardFooter: {
    background: theme.grey5,
    padding: [12, 12, 6],
    display: 'flex',
    flexDirection: 'row',
  },
  offerCardTotal: {
    fontSize: '16px',
    fontWeight: 500,
    color: theme.greyDark,
  },
  offerCardTotalField: {
    marginRight: 24,
    color: theme.greyDark,
  },
  listedCasesRow: {
    fontSize: '16px',
    lineHeight: '1em',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  offerCardButtons: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
  unawardButton: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  },
  awardButton: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  },
  svg: {
    fill: theme.grey50,
    height: '24px',
    width: '24px',
    display: 'inline-block',
    '&:hover': {
      fill: theme.text,
    },
  },
  belowReserveSvg: {
    fill: theme.grey50,
    height: '14px',
    width: '14px',
    marginBottom: '2px',
    display: 'inline-block',
    marginLeft: '7px',
  },
  negotiateIcon__button: {
    marginRight: '8px',
  },
  ignoreRestore__button: {
    marginRight: '8px',
    background: 'rgba(255, 255, 255, 1)',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  },
  truckTooltipBox: {
    padding: 8,
    textAlign: 'left',
    '& > :nth-child(3n-2)': {
      marginBottom: 8,
    },
    '& > :last-child': {
      marginBottom: 0,
    },
  },
  truckTooltipSubtitle: {
    fontWeight: 500,
  },
  belowReserveIndicator: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontStretch: 'condensed',
    fontStyle: 'normal',
    fontSize: '10px',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#fff',
    verticalAlign: 'top',
  },
  belowReserveIndicatorBackground: {
    padding: '1px',
    borderRadius: '1px',
    backgroundColor: '#e36363',
    display: 'inline-block',
    height: '11px',
    margin: '2px 0 0 5px',
    verticalAlign: 'top',
  },
  belowReservePriceIndicatorContainer: {
    display: 'inline-block',
  },
  averagePriceContainer: {
    margin: '0 10px',
  },
  quickEntryWithTextArea: {
    top: '50px',
    left: '-185px',
    zIndex: 1,
    backgroundColor: theme.white,
  },
  quickEntry: {
    top: '45px',
    left: '-50px',
    zIndex: 1,
    backgroundColor: theme.white,
  },
};

export default styles;
