import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import {
  Theme,
  MarketingOfferShiftArrowsIcon,
  MarketingPartialOfferShiftArrowsIcon,
  DataTableNaked,
  RowAction,
  AwardIcon,
  Flyout,
  OverlayService,
  AlertService,
  Timing,
  InformationIconSmall,
  MiniTooltip,
} from '@spoiler-alert/ui-library';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import accounting from 'accounting';
import { useMutation } from '@apollo/client';
import { shiftedOfferColumns } from './offer-review-shift-card-columns';
import OfferReviewShiftDetail from './offer-review-shift-detail';
import { AcceptShiftedOfferListings } from '../../graphql/mutations';
import { AwardSummaryQuery } from '../../graphql/queries';

const useStyles = createUseStyles({
  border: {
    border: `1px ${Theme.borderColor} solid`,
    borderRadius: '4px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    extend: 'border',
    backgroundColor: Theme.grey5,
    padding: '12px',
    '&:not(:last-child)': {
      marginBottom: '24px',
    },
  },
  card: {
    extend: 'border',
    backgroundColor: Theme.white,
  },
  offerCard: {
    extend: 'card',
    padding: '12px',
  },
  shiftTable: {
    borderTop: `1px ${Theme.borderColor} solid`,
    borderRadius: '4px',
    paddingTop: '2px',
  },
  header: {
    width: '108px',
    height: '20px',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
    marginBottom: 12,
  },
  shiftHeader: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
    margin: 12,
  },
  offerTable: {
    borderCollapse: 'separate',
    textAlign: 'left',
    '& th': {
      paddingRight: '24px',
      color: Theme.tableHeaderTextColor,
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: '12px',
    },
    '& td': {
      paddingRight: '24px',
      fontSize: '14px',
    },
  },
  arrowIcon: {
    transform: 'rotate(180deg)',
    width: '24px',
    height: '20px',
    flexGrow: 0,
    objectFit: 'contain',
    margin: '0px -4px 0px 12px',
  },
  informationIcon: {
    height: 20,
    width: 20,
    marginLeft: 8,
    cursor: 'pointer',
    fill: Theme.greyDark,
  },
  bestByDate: {
    extend: 'row',
    marginRight: 12,
  },
  tooltipText: {
    minWidth: '250px',
    fontWeight: 'normal',
  },
  details: {
    width: '600px',
  },
  detailsContainer: {
    height: '100%',
  },
  detailsContents: {
    padding: 0,
  },
  shiftTypeHeader: {
    extend: 'row',
    marginBottom: 8,
    '& p': {
      fontSize: '12px',
      fontWeight: 500,
      margin: 0,
    },
  },
});

const shiftType = {
  PARTIAL: 'PARTIAL',
  FULL: 'FULL',
};

const OfferReviewShiftCard = ({ offer, buyerName }) => {
  const classes = useStyles();
  const [acceptShifts] = useMutation(AcceptShiftedOfferListings);
  const [loadingRows, setLoadingRows] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [currentOffer, setCurrentOffer] = useState(null);
  const isPartialShift = offer?.offerShifts?.[0]?.shiftType === shiftType.PARTIAL;
  const tableData = [
    {
      header: 'SKU',
      value: offer.itemName,
      key: uuidv4(),
    },
    {
      header: 'DESCRIPTION',
      value: offer.itemDescription,
      key: uuidv4(),
    },
    {
      header: 'BEST BY',
      value: `${moment(offer.bestByDate).format('MM/DD/YYYY')} (${moment(offer.bestByDate)
        .startOf('day')
        .diff(moment().startOf('day'), 'days')} days)`,
      key: uuidv4(),
    },
    {
      header: 'BID QTY',
      value: offer.buyerProposedQuantity,
      key: uuidv4(),
    },
    {
      header: 'AWARDED QTY',
      value: offer.buyerProposedQuantity - offer.quantity,
      key: uuidv4(),
    },
    {
      header: 'UNALLOCATED QTY',
      value: offer.quantity,
      key: uuidv4(),
    },
    {
      header: 'BID PRICE',
      value: accounting.formatMoney(offer.unitPrice),
      key: uuidv4(),
    },
    {
      header: 'TOTAL BID',
      value: accounting.formatMoney(offer.unitPrice * offer.quantity),
      key: uuidv4(),
    },
    {
      header: 'STATUS',
      value: isPartialShift ? 'Partially Awarded' : 'Unawarded',
      key: uuidv4(),
    },
  ];

  const { offerType, tooltipText, shiftArrowIcon } = useMemo(() => {
    if (isPartialShift) {
      return {
        offerType: 'PARTIAL OFFER SHIFT',
        tooltipText:
          'Only part of the requested quantity on the original offer was awarded. The unallocated quantity can be shifted to similar leftover inventory.',
        shiftArrowIcon: <MarketingPartialOfferShiftArrowsIcon style={{ margin: '2px 0px' }} />,
      };
    }
    return {
      offerType: 'FULL OFFER SHIFT',
      tooltipText:
        'The original offer was not awarded because there was no available inventory left to award. The offer can be shifted to similar leftover inventory.',
      shiftArrowIcon: <MarketingOfferShiftArrowsIcon style={{ margin: '2px 0px' }} />,
    };
  }, [offer]);

  const columns = useMemo(() => {
    return shiftedOfferColumns(classes, offer);
  }, [offer]);

  const disabledRows = useMemo(() => {
    return offer.offerShifts.map((shiftOffer) => {
      if (shiftOffer.status === 'ACCEPTED') return shiftOffer._id;
      return null;
    });
  }, [offer]);

  const originalOfferTable = useMemo(() => {
    const tableHeaders = [];
    const tableValues = [];

    tableData.forEach((data) => {
      tableHeaders.push(<th key={`${data.header}-${data.key}`}>{data.header}</th>);
      tableValues.push(<td key={`${data.value}-${data.key}`}>{data.value}</td>);
    });

    return (
      <table className={classes.offerTable}>
        <thead>
          <tr>{tableHeaders}</tr>
        </thead>
        <tbody>
          <tr>{tableValues}</tr>
        </tbody>
      </table>
    );
  }, [offer]);

  const removeLoadingArray = (id) => {
    const index = loadingRows.indexOf(id);
    const arrayDup = [...loadingRows];
    if (index > -1) {
      arrayDup.splice(index, 1);
    }
    setLoadingRows(arrayDup);
  };

  const handleOfferShift = Timing.throttle(async (row, e) => {
    e.stopPropagation();
    setLoadingRows([...loadingRows, row._id]);
    try {
      const results = await acceptShifts({
        variables: {
          shiftedOfferListings: [
            {
              shiftedInventoryId: row.shiftInventoryId,
              shiftedQuantity: row.shiftedQuantity,
              pricePerCase: row.unitPrice,
              logisticsOption: row.logisticsOptions,
              originalOfferId: offer._id,
              id: row._id,
              shiftType: row.shiftType,
            },
          ],
          buyerSiteName: buyerName,
        },
        refetchQueries: [{ query: AwardSummaryQuery }],
      });

      if (results.data.acceptShiftedOfferListings.errors.length) throw new Error(results.data.acceptShiftedOfferListings.errors[0].message);
    } catch (error) {
      AlertService.alert({
        type: 'warning',
        message: (
          <span>
            {error.message
              ? error.message
              : 'Sorry there was an error accepting offer shifts. If this problem persists, please contact a Spoiler Alert Administrator to help you.'}
          </span>
        ),
      });
    }
    removeLoadingArray(row._id);
  }, 1000);

  const shiftingRowActions = () => {
    return [
      <RowAction
        key={1}
        tooltipText="Shift Offer & Award"
        loadingTooltipText="Shifting & Awarding Offer"
        icon={AwardIcon}
        loadingRows={loadingRows}
        disabledRows={disabledRows}
        onClick={(row) => handleOfferShift.bind(this, row)}
      />,
    ];
  };

  const toggleDetails = (row) => {
    if (showDetails) {
      OverlayService.hide();
      setShowDetails(false);
    } else {
      OverlayService.show();
      setShowDetails(true);
      setCurrentOffer(row);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.shiftTypeHeader}>
        <p>{offerType}</p>
        <MiniTooltip
          text={
            <div className={classes.tooltipText}>
              <span style={{ whiteSpace: 'pre-line', textTransform: 'none', lineHeight: 'normal' }}>{tooltipText}</span>
            </div>
          }
        >
          <InformationIconSmall className={classes.informationIcon} />
        </MiniTooltip>
      </div>
      <div className={classes.offerCard}>
        <p className={classes.header}>ORIGINAL OFFER</p>
        {originalOfferTable}
      </div>
      {shiftArrowIcon}
      <div className={classes.card}>
        <p className={classes.shiftHeader}>LEFTOVER INVENTORY</p>
        <div className={classes.shiftTable}>
          <DataTableNaked data={offer.offerShifts} rowActions={shiftingRowActions()} columns={columns} onRowClick={toggleDetails} />
        </div>
      </div>
      <Flyout
        position="right"
        classes={{ flyout__container: classes.detailsContainer, flyout__wrapper: classes.details, flyout__contents: classes.detailsContents }}
        open={showDetails}
        onHide={toggleDetails}
      >
        <OfferReviewShiftDetail offer={offer} shiftedOffer={currentOffer} />
      </Flyout>
      {showDetails ? <div id="hideScroll"></div> : <></>}
    </div>
  );
};

OfferReviewShiftCard.propTypes = {
  offer: PropTypes.object,
  buyerName: PropTypes.string,
};

export default OfferReviewShiftCard;
