import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { Banner, Button, Handshake, Theme as theme, OfferIcon, AlertService } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import accounting from 'accounting';
import pluralize from 'pluralize';
import { SetNegotiationRules, createRecommendedNegotiationStagedListings } from '../../graphql/mutations';
import { PossibleNegotiationsSummary, StagedNegotiationSummaryQuery } from '../../graphql/queries';
import { negotiationOrigin } from '../../enums';
import routePaths from '../../route-paths';

const styles = {
  bannerWrapper: {
    marginBottom: 24,
  },
  setRules: {
    color: theme.infoColor,
    cursor: 'pointer',
  },
  noMatches: {
    color: theme.errorColor,
    marginRight: 16,
  },
  note: {
    fontSize: 12,
    color: theme.grey80,
    fontStyle: 'italic',
    marginBottom: 12,
  },
  settings: {
    display: 'flex',
    marginBottom: 12,
  },
  settingLeft: {
    borderRight: `solid 1px ${theme.borderColor}`,
    paddingRight: 24,
    margin: '4px 0',
  },
  settingRight: {
    paddingLeft: '24px',
    margin: '4px 0',
  },
  settingHeader: {
    fontSize: 12,
    color: theme.grey80,
    textTransform: 'uppercase',
    margin: '4px 0',
  },
  inputLine: {
    border: 'none',
    borderBottom: `solid 2px ${theme.grey50}`,
    width: 40,
    '&:focus': {
      outline: 'none',
      padding: '0 0 4px 0',
    },
  },
  inputLineFocused: {
    border: 'none',
    borderBottom: `solid 2px ${theme.grey50}`,
    padding: '0 0 4px 0',
    width: 40,
    '&:focus': {
      outline: 'none',
    },
  },
  offerIcon: {
    stroke: theme.green,
    marginRight: 8,
    width: '14px',
  },
};

const RecommendedNegotiationsV2 = ({ history }) => {
  const useStyles = createUseStyles(styles);
  const classes = useStyles();

  const [isEditing, setIsEditing] = useState(false);
  const [lowerCutoff, setLowerCutoff] = useState('');
  const [counterOfferNumber, setCounterOfferNumber] = useState('');

  const [setNegotiationRules, { loading: loadingSetRules }] = useMutation(SetNegotiationRules);
  const [stageNegotiations, { loading: loadingStageNegotiations }] = useMutation(createRecommendedNegotiationStagedListings);
  const {
    data: negotiationsData,
    refetch: refetchNegotiations,
    loading: loadingQuery,
  } = useQuery(PossibleNegotiationsSummary, { fetchPolicy: 'network-only' });
  const estimatedRevenueIncrease = negotiationsData?.possibleNegotiationsSummaryQuery?.estimatedRevenueIncrease;
  const offerListingsToNegotiate = negotiationsData?.possibleNegotiationsSummaryQuery?.offerListingsToNegotiate;
  const numberOfNegotiations = negotiationsData?.possibleNegotiationsSummaryQuery?.offerListingsToNegotiate.length;
  const showNegotiations = negotiationsData?.possibleNegotiationsSummaryQuery?.offerListingsToNegotiate.length > 0;
  const existingNegotiationLowerCutoff = negotiationsData?.possibleNegotiationsSummaryQuery?.negotiationLowerCutoff;
  const existingCounterOfferNumber = negotiationsData?.possibleNegotiationsSummaryQuery?.counterOfferNumber;
  const isReservePriceEnabled = negotiationsData?.possibleNegotiationsSummaryQuery?.isReservePriceEnabled;

  useEffect(() => {
    if (existingNegotiationLowerCutoff || existingNegotiationLowerCutoff === 0) {
      setLowerCutoff(existingNegotiationLowerCutoff);
    }
    if (existingCounterOfferNumber) {
      setCounterOfferNumber(existingCounterOfferNumber);
    }
    setIsEditing(false);
  }, [existingNegotiationLowerCutoff, existingCounterOfferNumber]);

  const handleLowerCutoffChange = (ev) => {
    if (isNaN(ev.target.value)) return;
    if (ev.target.value < 0 || ev.target.value > 100) return;
    setLowerCutoff(ev.target.value === '' ? '' : parseInt(ev.target.value, 10));
  };

  const handleCounterOfferChange = (ev) => {
    if (isNaN(ev.target.value)) return;
    if (ev.target.value === '0') return;
    setCounterOfferNumber(ev.target.value === '' ? '' : parseInt(ev.target.value, 10));
  };

  const showError = (action) => {
    return AlertService.alert({
      type: 'warning',
      message: (
        <span>{`We were unable to ${action} due to an unknown error. Our team has been notified and are looking into the issue. Please contact customer support if the issue persists.`}</span>
      ),
      autoDismiss: true,
    });
  };

  const stageRecommendedNegotiations = () => {
    const cleanedOfferListingsToNegotiate = offerListingsToNegotiate.map((ol) => ({
      offerListingId: ol.id,
      counterOfferQuantity: ol.quantity,
      counterOfferAnchor: 'ReservePrice',
      counterOfferPercent: counterOfferNumber,
    }));
    const variables = {
      negotiationOrigin: negotiationOrigin.RESERVE_PRICE_BANNER,
      counterOffers: cleanedOfferListingsToNegotiate,
    };
    stageNegotiations({ variables, refetchQueries: [{ query: StagedNegotiationSummaryQuery }] })
      .then((result) => {
        const { errors } = result.data.createRecommendedNegotiationStagedListings;
        if (errors && errors.length > 0) {
          showError(errors[0].message);
        }
        const message = `${numberOfNegotiations} ${pluralize('Negotiation', numberOfNegotiations)} Staged`;
        AlertService.alert({
          message: <span>{message}</span>,
          autoDismiss: true,
          dismissDelay: 6000,
        });
        refetchNegotiations();
        history.push(routePaths.negotiations);
      })
      .catch(() => {
        const action = 'stage negotiations';
        showError(action);
      });
  };

  const saveRules = async () => {
    if (parseInt(lowerCutoff, 10) === existingNegotiationLowerCutoff && parseInt(counterOfferNumber, 10) === existingCounterOfferNumber) {
      setIsEditing(false);
      return;
    }
    const result = await setNegotiationRules({
      variables: {
        negotiationLowerCutoff: parseInt(lowerCutoff, 10),
        counterOfferNumber: parseInt(counterOfferNumber, 10),
      },
    });
    if (result.errors || result.data.setNegotiationRules.errors.length > 0) {
      const action = 'save the rules';
      showError(action);
    }
    refetchNegotiations();
  };

  const noRulesView = () => {
    return (
      <div>
        Customize your negotiation rules to get started!{' '}
        <span className={classes.setRules} onClick={() => setIsEditing(true)}>
          Set your rules now
        </span>
      </div>
    );
  };

  const editRulesView = () => {
    return (
      <div>
        <div className={classes.settings}>
          <div className={classes.settingLeft}>
            <div className={classes.settingHeader}>stage offers that are</div>
            <div>
              Between&nbsp;&nbsp;
              <input
                tabIndex={0}
                type="text"
                className={lowerCutoff ? classes.inputLineFocused : classes.inputLine}
                value={lowerCutoff}
                onChange={handleLowerCutoffChange}
              />
              %&nbsp;&nbsp; and 100% of reserve price.
            </div>
          </div>
          <div className={classes.settingRight}>
            <div className={classes.settingHeader}>set counter offers to</div>
            <div>
              <input
                type="text"
                className={counterOfferNumber ? classes.inputLineFocused : classes.inputLine}
                value={counterOfferNumber}
                onChange={handleCounterOfferChange}
              />
              %&nbsp;&nbsp; of reserve price.
            </div>
          </div>
        </div>
        <div className={classes.note}>We only select negotiations based on predicted success.</div>
        <Button
          secondary
          disabled={lowerCutoff === '' || lowerCutoff === undefined || counterOfferNumber === '' || counterOfferNumber === undefined}
          onClick={saveRules}
          loading={loadingSetRules || loadingQuery}
          loadingText="Saving Rules"
        >
          Save Rules
        </Button>
      </div>
    );
  };

  const rulesSavedView = () => {
    return (
      <div>
        <div className={classes.settings}>
          <div className={classes.settingLeft}>
            <div className={classes.settingHeader}>stage offers that are</div>
            <div>Between {lowerCutoff}% and 100% of reserve price.</div>
          </div>
          <div className={classes.settingRight}>
            <div className={classes.settingHeader}>set counter offers to</div>
            <div>{counterOfferNumber}% of reserve price.</div>
          </div>
        </div>
        <div className={classes.note}>We only select negotiations based on predicted success.</div>
        <div>
          {!showNegotiations && <span className={classes.noMatches}>No offers match your negotiation rules</span>}
          <span className={classes.setRules} onClick={() => setIsEditing(true)}>
            Edit negotiation rules
          </span>
        </div>
      </div>
    );
  };

  const reserveNotSetView = () => {
    return (
      <div>
        To negotiate in bulk you need to set a reserve price. Set a reserve price in the{' '}
        <span className={classes.setRules} onClick={() => history.push('/items')}>
          items screen
        </span>
        , or contact your customer success manager for more information.
      </div>
    );
  };

  const getContent = () => {
    if (!isReservePriceEnabled) return reserveNotSetView();
    if (isEditing) return editRulesView();
    if (existingCounterOfferNumber && (existingNegotiationLowerCutoff || existingNegotiationLowerCutoff === 0)) return rulesSavedView();
    if (isReservePriceEnabled && !existingCounterOfferNumber && !existingNegotiationLowerCutoff) return noRulesView();
    return null;
  };

  const getDetails = () => {
    if (showNegotiations && !isEditing)
      return (
        <div>
          <OfferIcon className={classes.offerIcon} />
          {`Potential Revenue: $${accounting.formatNumber(estimatedRevenueIncrease, 2)}`}
        </div>
      );
    return null;
  };

  return (
    <div className={classes.bannerWrapper}>
      {!loadingQuery && (
        <Banner
          icon={Handshake}
          content={getContent()}
          header={'Negotiate offers below reserve price to maximize sell through rate and revenue'}
          action={{
            name:
              showNegotiations && !isEditing
                ? `Stage ${numberOfNegotiations} ${pluralize('Negotiation', numberOfNegotiations)}`
                : 'Stage Negotiations',
            loading: loadingStageNegotiations,
            loadingText: 'Staging Negotiations',
            onClick: () => {
              stageRecommendedNegotiations();
            },
            disabled: !showNegotiations || isEditing,
            details: getDetails(),
          }}
        />
      )}
    </div>
  );
};

RecommendedNegotiationsV2.propTypes = {
  history: PropTypes.object,
};

export default RecommendedNegotiationsV2;
