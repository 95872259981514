import React from 'react';
import accounting from 'accounting';
import { PropTypes } from 'mobx-react';
import { createUseStyles } from 'react-jss';
import {
  Theme,
  MiniTooltip,
  Button,
  MultiButton,
  UnawardIcon,
  AlertService,
  icons,
  AwardIcon,
  NegotiateIcon,
  QuickEntry,
} from '@spoiler-alert/ui-library';
import { event } from 'react-fullstory';
import styles from './offer-card-footer-styles';
import { Field } from './helpers';
import { NegotiationStrings } from '../../string-resources';
import { negotiationOrigin } from '../../enums';
import QuickEntryWithTextArea from './quick-entry';
import featureFlags from '../../enums/feature-flags';
import checkFeature from '../../helpers/check-feature-flag';

const useStyles = createUseStyles(styles, { name: 'OfferCardFooter' });

const OfferCardFooter = ({
  ignoredClass,
  offerInfo,
  quantity,
  pricePerCase,
  isAwarded,
  isDonation,
  handleUnawardAndNegotitate,
  checkAwardQuantity,
  isIgnored,
  actionInProgress,
  ignoreInProgress,
  negotiationInProgress,
  handleRestore,
  handleIgnore,
  handleUnaward,
  canAward,
  disabled,
  refetchQueries,
  UnawardAndNegotiateOfferListing,
  handleNegotiation,
  createNegotiationStagedListings,
  user,
}) => {
  const classes = useStyles();
  const totalPrice = quantity * pricePerCase;
  const estimatedNetOffer = totalPrice - (offerInfo.logisticsCost / offerInfo.quantity) * quantity;
  const negotiationContextEnabled = checkFeature(featureFlags.negotiationContext);

  const onQuickEntryError = () => {
    AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{NegotiationStrings.genericError}</span> });
  };

  const onQuickEntryResponse = (result, mutationName) => {
    if (result?.[mutationName]?.errors?.length > 0) {
      onQuickEntryError();
    } else {
      const negotiationQuantityChanged = quantity !== result?.[mutationName]?.negotiationStagedListings[0].suggestedQuantity;
      const negotiationPriceChanged = pricePerCase !== result?.[mutationName]?.negotiationStagedListings[0].suggestedUnitPrice;
      if (!negotiationQuantityChanged && !negotiationPriceChanged) {
        event(`OC - Negotiation Staged Secondary - No Adjustment`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      } else if (negotiationQuantityChanged && negotiationPriceChanged)
        event(`OC - Negotiation Staged - Price & Qty Adjusted`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      else if (negotiationQuantityChanged)
        event(`OC - Negotiation Staged - Qty Adjusted`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      else if (negotiationPriceChanged)
        event(`OC - Negotiation Staged - Price Adjusted`, {
          userId: user._id,
          offerId: offerInfo._id,
        });
      const messageInfo = `Negotiation started with ${offerInfo.offer.buyerSite.siteName}`;
      AlertService.alert({ type: 'success', message: <span>{messageInfo}</span>, autoDismiss: true });
    }
  };

  const onUnawardQuickEntryResponse = (response) => {
    onQuickEntryResponse(response, 'unawardAndNegotiateOfferListing');
  };

  const onNegotiateQuickEntryResponse = (response) => {
    onQuickEntryResponse(response, 'createNegotiationStagedListings');
  };

  const inputsDisabled = () => {
    return (
      disabled ||
      actionInProgress ||
      ignoreInProgress ||
      negotiationInProgress ||
      isAwarded ||
      isIgnored ||
      quantity > offerInfo.inventory.availableQuantity ||
      offerInfo.inventory.availableQuantity <= 0
    );
  };

  const renderNegotiateButton = (awarded) => {
    const primaryAction = awarded
      ? { onClick: handleUnawardAndNegotitate, tooltipText: 'Unaward & Negotiate' }
      : { onClick: handleNegotiation, tooltipText: 'Negotiate' };

    const quickEntryArgs = awarded
      ? [
          { name: 'counterOfferQuantity', value: quantity, labelText: 'COUNTER QTY', max: offerInfo.listing.originalQuantity },
          { name: 'counterOfferPrice', value: pricePerCase, labelText: 'COUNTER PRICE', type: 'money' },
        ]
      : [
          { name: 'counterOffers.0.counterOfferQuantity', value: quantity, labelText: 'COUNTER QTY', max: offerInfo.listing.originalQuantity },
          { name: 'counterOffers.0.counterOfferPrice', value: pricePerCase, labelText: 'COUNTER PRICE', type: 'money' },
        ];

    const mutationArgs = awarded
      ? { id: offerInfo._id, negotiationOrigin: negotiationOrigin.OFFER_CARD_SECONDARY, counterOfferAnchor: 'Manual' }
      : {
          counterOffers: [
            { offerListingId: offerInfo._id, counterOfferQuantity: quantity, counterOfferPrice: pricePerCase, counterOfferAnchor: 'Manual' },
          ],
          negotiationOrigin: negotiationOrigin.OFFER_CARD_SECONDARY,
        };

    const alwaysDisabled = isIgnored || actionInProgress || ignoreInProgress || negotiationInProgress;
    const unawardedDisabled = quantity > offerInfo.inventory.availableQuantity || offerInfo.inventory.availableQuantity <= 0;
    const latestNegotiationHistory = offerInfo.negotiations
      ? offerInfo.negotiations[offerInfo.negotiations?.length - 1]?.negotiationHistory
      : undefined;
    return (
      <>
        <MultiButton
          primaryAction={primaryAction}
          secondaryTooltipText={awarded ? 'Unaward & Adjust Counter Offer' : 'Adjust Counter Offer'}
          disabled={awarded ? alwaysDisabled : unawardedDisabled || alwaysDisabled}
          loading={negotiationInProgress}
          secondary
          primaryIcon={NegotiateIcon}
          child={
            negotiationContextEnabled ? (
              <QuickEntryWithTextArea
                mutation={awarded ? UnawardAndNegotiateOfferListing : createNegotiationStagedListings}
                mutationArgs={mutationArgs}
                quickEntryArgs={quickEntryArgs}
                onResponse={awarded ? onUnawardQuickEntryResponse : onNegotiateQuickEntryResponse}
                onError={onQuickEntryError}
                className={awarded ? `${classes.quickEntryWithTextArea} awarded` : classes.quickEntryWithTextArea}
                icon={NegotiateIcon}
                refetchQueries={refetchQueries(true)}
                quantity={quantity}
                originalQuantity={offerInfo.listing.originalQuantity}
                pricePerCase={pricePerCase}
                reason={latestNegotiationHistory ? latestNegotiationHistory[latestNegotiationHistory?.length - 1]?.reason : ''}
              />
            ) : (
              <QuickEntry
                buttonText="Negotiate"
                mutation={awarded ? UnawardAndNegotiateOfferListing : createNegotiationStagedListings}
                mutationArgs={mutationArgs}
                quickEntryArgs={quickEntryArgs}
                onResponse={awarded ? onUnawardQuickEntryResponse : onNegotiateQuickEntryResponse}
                onError={onQuickEntryError}
                className={awarded ? `${classes.quickEntry} awarded` : classes.quickEntry}
                icon={NegotiateIcon}
                refetchQueries={refetchQueries(true)}
              />
            )
          }
        />
      </>
    );
  };

  const renderRestoreButton = () => (
    <MiniTooltip text="Restore">
      <Button
        className={classes.ignoreRestore__button}
        onClick={handleRestore}
        disabled={ignoreInProgress || actionInProgress || negotiationInProgress}
        loading={ignoreInProgress}
        icon={icons.restore}
        readOnly={inputsDisabled()}
        secondary
      />
    </MiniTooltip>
  );

  const getIgnoreTooltipText = () => {
    if (!isAwarded && offerInfo.negotiationStatus === 'STAGED') return 'Unstage & Ignore';
    return 'Ignore';
  };

  const renderIgnoreButton = () => (
    <MiniTooltip text={getIgnoreTooltipText()}>
      <Button
        className={classes.ignoreRestore__button}
        onClick={handleIgnore}
        disabled={ignoreInProgress || actionInProgress || negotiationInProgress || isAwarded}
        loading={ignoreInProgress}
        icon={icons.ignore}
        readOnly={inputsDisabled()}
        secondary
      />
    </MiniTooltip>
  );

  const renderAwardedButtons = () => (
    <>
      {offerInfo.status !== 'IGNORED' ? renderIgnoreButton() : renderRestoreButton()}
      {isDonation ? false : renderNegotiateButton(true)}
      <MiniTooltip text="Unaward">
        <Button
          inline
          warning
          onClick={handleUnaward}
          className={classes.unawardButton}
          icon={UnawardIcon}
          loading={actionInProgress}
          disabled={actionInProgress || ignoreInProgress || negotiationInProgress}
        />
      </MiniTooltip>
    </>
  );

  const renderUnawardedButtons = () => (
    <>
      {offerInfo.status !== 'IGNORED' ? renderIgnoreButton() : renderRestoreButton()}
      {isDonation ? false : renderNegotiateButton(false)}
      <MiniTooltip text="Award" disabled={inputsDisabled()}>
        <Button
          onClick={checkAwardQuantity}
          disabled={inputsDisabled() || !canAward()}
          loading={actionInProgress}
          icon={AwardIcon}
          readOnly={inputsDisabled()}
          className={classes.unawardButton}
        />
      </MiniTooltip>
    </>
  );

  return (
    <div className={`${classes.offerCardFooter} ${ignoredClass}`}>
      <Field className={`${classes.offerCardTotalField} ${ignoredClass}`} label="Total Offer" color={Theme.greyDark}>
        <br />
        <span className={classes.offerCardTotal}>{accounting.formatMoney(totalPrice)}</span>
      </Field>
      {offerInfo.logisticsCost > 0 && (
        <Field className={`${classes.offerCardTotalField} ${ignoredClass}`} label="Estimated Net Offer">
          <span className={classes.offerCardTotal}>{accounting.formatMoney(estimatedNetOffer)}</span>
        </Field>
      )}
      <div className={classes.offerCardButtons}>{isAwarded ? renderAwardedButtons() : renderUnawardedButtons()}</div>
    </div>
  );
};

OfferCardFooter.propTypes = {
  ignoredClass: PropTypes.string,
  offerInfo: PropTypes.object,
  quantity: PropTypes.number,
  pricePerCase: PropTypes.number,
  isAwarded: PropTypes.bool,
  isDonation: PropTypes.bool,
  handleUnawardAndNegotitate: PropTypes.func,
  checkAwardQuantity: PropTypes.func,
  isIgnored: PropTypes.bool,
  actionInProgress: PropTypes.bool,
  ignoreInProgress: PropTypes.bool,
  negotiationInProgress: PropTypes.bool,
  handleRestore: PropTypes.func,
  handleIgnore: PropTypes.func,
  handleUnaward: PropTypes.func,
  canAward: PropTypes.func,
  disabled: PropTypes.bool,
  refetchQueries: PropTypes.func,
  UnawardAndNegotiateOfferListing: PropTypes.func,
  handleNegotiation: PropTypes.func,
  createNegotiationStagedListings: PropTypes.func,
  user: PropTypes.object,
};
export default OfferCardFooter;
