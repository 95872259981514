import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { AlertService, SelectBox, Button, Select, SelectOption, InformationIcon, Timing, Theme } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import { useMutation } from '@apollo/client';
import { event } from 'react-fullstory';
import { TrucklaneFilterZeroState, TrucklaneNonSuggestionZeroState, TrucklaneSuggestionZeroState } from './trucklane-zero-state';
import TrucklaneCard from './trucklane-card';
import { AwardSuggestedOfferListings, UnawardOfferListings } from '../../graphql/mutations';
import { OfferComparisonStrings } from '../../string-resources';
import { offerlistingStatuses } from '../../enums';
import { buildSortOptions, sortPallet, sortRevenue, sortSuggestedRevenue, sortWeight } from './trucklane-filter-utility';
import { AwardSummaryQuery, trucklanePollingQuery } from '../../graphql/queries';
import { Store } from '../../store';
import { invalidErrorCodes } from './trucklane-utility';

const styles = {
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .childRow': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  selectBox: {
    marginRight: '8px',
  },
  sort: {
    minWidth: '260px',
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      height: 150,
      width: 300,
    },
    '& h1': {
      extend: 'title',
      marginTop: '10px',
      marginBottom: '48px',
    },
  },
  filter: {
    extend: 'row',
    backgroundColor: Theme.teal10,
    marginTop: 24,
    '& h1': {
      fontSize: 14,
      fontWeight: 'normal',
      margin: '12px 8px',
      textWrap: 'nowrap',
    },
    '& svg': {
      maxWidth: 16,
      maxHeight: 16,
      width: 16,
      height: 16,
      margin: '13px 0px 11px 12px',
      fill: Theme.infoColor,
    },
    '& a': {
      backgroundColor: 'transparent',
      color: Theme.infoColor,
      fontSize: 14,
      marginRight: 12,
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
};
const useStyles = createUseStyles(styles);

const TrucklaneBlock = ({ title, trucklaneData, buttonDetails, suggested, history, user, filters, clearFilters, openDrilldown }) => {
  let cache = null;
  const [awardOffersMutation, { loading: awardOffersLoading }] = useMutation(AwardSuggestedOfferListings);
  const [unawardOffersMutation, { loading: unawardOffersLoading }] = useMutation(UnawardOfferListings);
  const classes = useStyles();
  const [sort, setSort] = useState(null);
  const [selected, setSelected] = useState([]);
  const [slideCard, setSlideCard] = useState(false);
  const sortOptions = buildSortOptions(suggested);

  const handleSortChange = useCallback(
    (_, value) => {
      if (suggested) {
        localStorage.setItem('trucklaneSuggestedSort', JSON.stringify(value));
      } else {
        localStorage.setItem('trucklaneNonSuggestedSort', JSON.stringify(value));
      }
      setSort(value);
    },
    [sort]
  );

  const getSortFromLocalStorage = () => {
    const localStorageKey = suggested ? 'trucklaneSuggestedSort' : 'trucklaneNonSuggestedSort';
    const sortValue = localStorage.getItem(localStorageKey);
    return sortValue ? JSON.parse(sortValue) : null;
  };

  const getDefaultSort = () => {
    const valueBase = suggested ? 'SUGGESTED_' : '';
    const textBase = suggested ? 'Suggested ' : '';
    const mode = user.site?.trucklaneMinimums?.mode;

    if (mode === 'TOTAL_REVENUE') {
      return { value: `HIGHEST_${valueBase}REV`, text: `Highest ${textBase}Revenue` };
    }

    return { value: `HIGHEST_${valueBase}NET_REV`, text: `Highest ${textBase}Net Revenue` };
  };

  useEffect(() => {
    setSort(getSortFromLocalStorage() || getDefaultSort());
  }, [user]);

  const sortedTrucklanes = useMemo(() => {
    if (sort) {
      const netMode = ['HIGHEST_NET_REV', 'LOWEST_NET_REV', 'HIGHEST_SUGGESTED_NET_REV', 'LOWEST_SUGGESTED_NET_REV'].includes(sort.value);
      const reverse = [
        'LOWEST_NET_REV',
        'LOWEST_REV',
        'LOWEST_SUGGESTED_NET_REV',
        'LOWEST_SUGGESTED_REV',
        'LOWEST_WEIGHT',
        'LOWEST_SUGGESTED_WEIGHT',
        'LOWEST_PALLET',
        'LOWEST_SUGGESTED_PALLET',
      ].includes(sort.value);
      let sortedData = [];
      if (['HIGHEST_SUGGESTED_NET_REV', 'LOWEST_SUGGESTED_NET_REV', 'HIGHEST_SUGGESTED_REV', 'LOWEST_SUGGESTED_REV'].includes(sort.value)) {
        sortedData = sortSuggestedRevenue(trucklaneData, netMode);
      } else if (['HIGHEST_WEIGHT', 'LOWEST_WEIGHT', 'HIGHEST_SUGGESTED_WEIGHT', 'LOWEST_SUGGESTED_WEIGHT'].includes(sort.value)) {
        sortedData = sortWeight(trucklaneData, suggested);
      } else if (sort.value.includes('PALLET')) {
        sortedData = sortPallet(trucklaneData, suggested);
      } else {
        sortedData = sortRevenue(trucklaneData, netMode);
      }
      if (reverse) return sortedData.every((l) => l.value === 0) ? sortedData.map((l) => l.trucklane) : sortedData.reverse().map((l) => l.trucklane);
      return sortedData.map((l) => l.trucklane);
    }
    return trucklaneData;
  }, [sort, trucklaneData]);

  const emptyState = useMemo(() => {
    if (!trucklaneData?.length && filters) return <TrucklaneFilterZeroState />;
    if (!trucklaneData?.length && !suggested) return <TrucklaneNonSuggestionZeroState />;
    if (!trucklaneData?.length) return <TrucklaneSuggestionZeroState history={history} />;
    return <></>;
  }, [trucklaneData]);

  const buildSort = useMemo(() => {
    return (
      <Select classes={{ select__container: classes.sort }} label="Sort By" selectedItem={sort} onChange={handleSortChange.bind(this)}>
        {sortOptions.map((option) => (
          <SelectOption key={option.value} value={option.value}>
            {option.title}
          </SelectOption>
        ))}
      </Select>
    );
  }, [sort]);

  const selectableTrucklaneIds = useMemo(() => {
    if (suggested) {
      return trucklaneData.filter((trucklane) => trucklane.status !== 'Invalid').map((trucklane) => trucklane.id);
    }
    return trucklaneData
      .filter((trucklane) => trucklane.offers.filter((offer) => offer.status === offerlistingStatuses.AWARDED).length)
      .map((trucklane) => trucklane.id);
  }, [trucklaneData]);

  const allSelected = () => {
    return trucklaneData && selected.length > 0 && selected.length === selectableTrucklaneIds.length;
  };

  const partialSelected = () => {
    return trucklaneData && selected.length > 0 && selected.length < selectableTrucklaneIds.length;
  };

  const selectAll = (result) => {
    setSlideCard(false);
    setSelected(result.checked ? selectableTrucklaneIds || [] : []);
  };

  function handleSelect(e) {
    setSlideCard(false);
    if (e.checked) {
      setSelected([...selected, e.value]);
    } else {
      setSelected(selected.filter((trucklaneId) => trucklaneId !== e.value));
    }
  }

  function deSelect(trucklaneId, singleDeselect = false) {
    if (singleDeselect) {
      setSelected(selected.filter((selectedIds) => selectedIds !== trucklaneId));
    } else {
      const deSelectedTrucklane = trucklaneData.find((trucklane) => trucklane.id === trucklaneId);
      const filteredLanes = trucklaneData
        .filter((trucklane) => trucklane.origin === deSelectedTrucklane.origin && trucklane.truckType === deSelectedTrucklane.truckType)
        .map((trucklane) => trucklane.id);
      setSelected(selected.filter((selectedIds) => !filteredLanes.includes(selectedIds)));
    }
  }

  const errorRefresh = () => {
    window.location.reload();
  };

  const awardSuggestions = Timing.throttle(() => {
    const trucklaneIds = [];
    const offerIds = trucklaneData
      .filter((trucklane) => {
        if (selected.includes(trucklane.id)) {
          trucklaneIds.push(trucklane.id);
          return true;
        }
        return false;
      })
      .flatMap((trucklane) => trucklane.offers)
      .filter((offer) => offer.suggestions?.award?.suggested && offer.status === offerlistingStatuses.ACTIVE)
      .map((offer) => offer._id);
    awardOffersMutation({
      variables: { offerListingIds: offerIds },
      update: (cacheResult) => {
        cache = cacheResult;
      },
      refetchQueries: [
        {
          query: AwardSummaryQuery,
        },
        {
          query: trucklanePollingQuery,
          variables: {
            pollingDetails: [...Store.trucklaneChannels.values()].map((channel) => ({ origin: channel.originDCId, truckType: channel.truckType })),
          },
        },
      ],
    })
      .then((resp) => {
        if (resp.data?.awardSuggestedOfferListings?.errors?.length) {
          const recalcError = resp.data?.awardSuggestedOfferListings?.errors.some((error) => invalidErrorCodes.includes(error.code));
          if (recalcError) {
            event(`There was a recalculation based error while awarding`, {
              userId: user._id,
            });
            return AlertService.alert({
              type: 'warning',
              autoDismiss: false,
              message: (
                <div>
                  <span>{OfferComparisonStrings.awardingError}</span>
                  <div>
                    <h1 className="error_action" onClick={errorRefresh}>
                      Refresh Suggested Awards
                    </h1>
                  </div>
                </div>
              ),
            });
          }
          throw Error();
        }
        AlertService.alert({ type: 'success', autoDismiss: true, message: <span>Suggested Offers have been awarded</span> });
        setSlideCard(true);
        event(`Offers were bulk awarded with suggestions in trucklane view`, {
          userId: user._id,
        });
        return setTimeout(() => {
          trucklaneIds.forEach((laneId) => {
            cache.modify({
              id: cache.identify({ id: laneId, __typename: 'Trucklane' }),
              fields: {
                hasSuggestions() {
                  return false;
                },
              },
            });
            setSelected([]);
          });
        }, 500);
      })
      .catch(() => AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{OfferComparisonStrings.awardingError}</span> }));
  }, 1000);

  const unawardOffers = Timing.throttle(() => {
    const offerIds = trucklaneData
      .filter((trucklane) => selected.includes(trucklane.id))
      .flatMap((trucklane) => trucklane.offers)
      .filter((offer) => offer.status === offerlistingStatuses.AWARDED)
      .map((offer) => offer._id);
    unawardOffersMutation({
      variables: { offerListingIds: offerIds },
      refetchQueries: [
        {
          query: AwardSummaryQuery,
        },
        {
          query: trucklanePollingQuery,
          variables: {
            pollingDetails: [...Store.trucklaneChannels.values()].map((channel) => ({ origin: channel.originDCId, truckType: channel.truckType })),
          },
        },
      ],
    })
      .then((resp) => {
        if (resp.data?.unawardOfferListings?.errors?.length) throw Error();
        AlertService.alert({ type: 'success', autoDismiss: true, message: <span>Offers have been unawarded</span> });
        setSelected([]);
        event(`Offers were bulk unawarded in trucklane view`, {
          userId: user._id,
        });
      })
      .catch(() => AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{OfferComparisonStrings.unawardingError}</span> }));
  }, 1000);

  const awardOrUnaward = () => {
    if (suggested) {
      return awardSuggestions();
    }
    return unawardOffers();
  };

  const selectAllIsDisabled = () => {
    if (suggested) {
      return !trucklaneData?.filter((trucklane) => trucklane.status !== 'Invalid').length;
    }
    return !trucklaneData?.flatMap((trucklane) => trucklane.offers).filter((offer) => offer.status === offerlistingStatuses.AWARDED).length;
  };

  return (
    <div className={classes.block}>
      <h1 className={classes.title}>
        {title} ({trucklaneData.length})
      </h1>
      <div className={classes.row}>
        <div className="childRow">
          <SelectBox
            disabled={selectAllIsDisabled()}
            className={classes.selectBox}
            partial={partialSelected()}
            all={allSelected()}
            onSelect={selectAll}
          />
          <Button
            onClick={awardOrUnaward}
            loading={awardOffersLoading || unawardOffersLoading}
            loadingText={buttonDetails.loadingText}
            disabled={!trucklaneData.length || !selected.length}
            warning={buttonDetails.warning}
            icon={buttonDetails.icon || null}
          >
            {buttonDetails.title}
          </Button>
        </div>
        {buildSort}
      </div>
      {filters && (
        <div className={classes.filter}>
          <div className="childRow">
            <InformationIcon />
            <h1>Some truck lanes are hidden from view based on your filters or search</h1>
          </div>
          <a onClick={clearFilters}>Clear All Filters</a>
        </div>
      )}
      {emptyState}
      {sortedTrucklanes?.length > 0 &&
        sortedTrucklanes.map((trucklane) => (
          <TrucklaneCard
            key={trucklane.id}
            trucklaneId={trucklane.id}
            suggested={suggested}
            offers={trucklane.offers}
            recalculation={trucklane.status === 'Invalid'}
            selected={selected.includes(trucklane.id)}
            deSelect={deSelect}
            handleSelect={handleSelect}
            awarded={selected.includes(trucklane.id) && slideCard}
            user={user}
            history={history}
            openDrilldown={openDrilldown}
          />
        ))}
    </div>
  );
};

TrucklaneBlock.propTypes = {
  title: PropTypes.string,
  trucklaneData: PropTypes.array,
  buttonDetails: PropTypes.object,
  suggested: PropTypes.bool,
  history: PropTypes.object,
  user: PropTypes.object,
  filters: PropTypes.bool,
  clearFilters: PropTypes.func,
  openDrilldown: PropTypes.func,
};

export default TrucklaneBlock;
