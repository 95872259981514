import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import accounting from 'accounting';
import PropTypes from 'prop-types';
import { Button, TextBox, Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  container: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    border: `solid 1px ${theme.grey50}`,
    borderRadius: '2px',
    padding: '10px',
    position: 'absolute',
    width: '310px',
    '&:before': {
      content: '""',
      position: 'absolute',
      border: '10px solid transparent',
      borderBottomColor: '#d9dee1',
      left: '221px',
      bottom: '100%',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      border: '9px solid transparent',
      borderBottomColor: '#fff',
      left: '222px',
      bottom: '100%',
    },
  },
  textBoxes: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '12px',
  },
  fieldLabel: {
    textTransform: 'uppercase',
    marginBottom: '4px',
    fontSize: '12px',
    color: theme.greyDark,
    fontWeight: 500,
    display: 'flex',
  },
  textBoxContainer: {
    marginBottom: '10px',
    padding: '5px',
    borderRadius: '2px',
    height: '32px',
  },
  textBoxInput: {
    textAlign: 'right',
    marginRight: '5px',
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '100% !important',
    display: 'flex',
    justifyContent: 'center',
    height: '36px',
    borderRadius: '2px',
    marginTop: '16px',
  },
  textArea: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  textAreaHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textAreaLabel: {
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    marginBottom: '2px',
  },
  characterCount: {
    width: '100%',
    fontSize: '12px',
    textTransform: 'uppercase',
    textAlign: 'right',
    color: ({ limitExceeded }) => (limitExceeded ? theme.red : theme.grey80),
  },
  message: {
    borderRadius: '2px',
    height: '84px',
    padding: '6px 12px',
    border: ({ limitExceeded }) => (limitExceeded ? `solid 1px ${theme.red}` : `solid 1px ${theme.grey30}`),
    outline: 'none',
    resize: 'none',
    '&:focus': {
      border: ({ limitExceeded }) => (limitExceeded ? `solid 1px ${theme.red}` : `solid 1px ${theme.text}`),
    },
    '&::placeholder': {
      color: theme.grey30,
    },
  },
};

const useStyles = createUseStyles(styles);

const QuickEntryWithTextArea = ({
  mutation,
  mutationArgs,
  onResponse,
  onError,
  icon,
  refetchQueries,
  quantity,
  pricePerCase,
  originalQuantity,
  reason,
  quickEntryArgs,
  className,
}) => {
  const [actionInProgress, setActionInProgress] = useState(false);

  const [counterQuantity, setCounterQuantity] = useState(quantity);
  const [counterOffer, setCounterOffer] = useState(pricePerCase);
  const [message, setMessage] = useState(reason || '');
  const classes = useStyles({ limitExceeded: message.length > 120 });
  const quickEntryContainerRef = useRef(null);
  const [runMutation] = useMutation(mutation, {
    onCompleted: (data) => {
      if (onResponse) onResponse(data);
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });

  const handleClick = () => {
    setActionInProgress(true);
    const variables = { ...mutationArgs, reason: message };
    quickEntryArgs[0].value = counterQuantity;
    quickEntryArgs[1].value = counterOffer;
    quickEntryArgs.forEach((arg) => {
      const path = arg.name.split('.');
      const variableName = path.reduce((r, x, i) => {
        if (path.length - 1 > i) return r[x];
        return r;
      }, variables);
      variableName[path[path.length - 1]] = arg.value;
    });
    runMutation({
      variables,
      refetchQueries,
    }).finally(() => {
      setActionInProgress(false);
    });
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const textAreaRef = useRef();

  return (
    <div className={`${classes.container} ${className}`} ref={quickEntryContainerRef}>
      <div className={classes.textBoxes}>
        <div>
          <div className={classes.fieldLabel}>Counter Qty</div>
          <TextBox
            small
            className={classes.textBoxContainer}
            value={counterQuantity}
            onChange={(newValue) => setCounterQuantity(accounting.unformat(newValue))}
            inputClassName={classes.textBoxInput}
            autoFocus={true}
            error={typeof counterQuantity !== 'number' || counterQuantity === 0 || counterQuantity > originalQuantity}
          />
        </div>
        <div>
          <div className={classes.fieldLabel}>Counter Offer</div>
          <TextBox
            small
            className={classes.textBoxContainer}
            value={accounting.formatMoney(counterOffer)}
            onChange={(newValue) => setCounterOffer(accounting.unformat(newValue))}
            inputClassName={classes.textBoxInput}
            error={typeof counterOffer !== 'number' || counterOffer === 0}
          />
        </div>
      </div>
      <div className={classes.textArea}>
        <div className={classes.textAreaHeader}>
          <div className={classes.textAreaLabel}>Negotiation message</div>
          <div className={classes.characterCount}>{`${message?.length || 0} / 120 characters`}</div>
        </div>
        <textarea
          data-element="text-area"
          value={message}
          onChange={handleMessageChange}
          className={classes.message}
          ref={textAreaRef}
          onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
          placeholder="This message will appear in your customer’s negotiation sheet on this inventory line."
        ></textarea>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          onClick={handleClick}
          loading={actionInProgress}
          loadingText={'Staging Negotiation'}
          icon={icon}
          disabled={
            message.length > 120 ||
            typeof counterOffer !== 'number' ||
            counterOffer === 0 ||
            typeof counterQuantity !== 'number' ||
            counterQuantity === 0 ||
            counterQuantity > originalQuantity
          }
        >
          Negotiate
        </Button>
      </div>
    </div>
  );
};

QuickEntryWithTextArea.propTypes = {
  mutation: PropTypes.object,
  mutationArgs: PropTypes.object,
  quantity: PropTypes.number,
  originalQuantity: PropTypes.number,
  pricePerCase: PropTypes.number,
  onResponse: PropTypes.func,
  onError: PropTypes.func,
  icon: PropTypes.elementType,
  refetchQueries: PropTypes.array,
  className: PropTypes.string,
  reason: PropTypes.string,
  quickEntryArgs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      type: PropTypes.string,
      labelText: PropTypes.string,
      max: PropTypes.number,
    })
  ),
};

export default QuickEntryWithTextArea;
