import React, { useState } from 'react';
import accounting from 'accounting';
import { PropTypes } from 'mobx-react';
import { event } from 'react-fullstory';
import { createUseStyles } from 'react-jss';
import { ChevronIcon } from '@spoiler-alert/ui-library';
import { weightWithUnit } from '../../utilities/unit-helper';
import styles from './offer-card-v2-styles';

const useStyles = createUseStyles(styles, { name: 'OfferCard' });

const TrucklaneDetails = ({ activeTruckLane, offerInfo, isAwarded, quantity, user, pricePerCase }) => {
  const classes = useStyles();
  const [chevronDirection, setChevronDirection] = useState('down');

  const offerAwarded = offerInfo.status === 'AWARDED';
  const summedRevenue = (activeTruckLane?.totalRevenue || 0) + (offerAwarded ? 0 : quantity * pricePerCase);
  const summedWeight = (activeTruckLane?.totalWeight || 0) + (offerAwarded ? 0 : quantity * offerInfo.inventory.unitGrossWeight);

  const onTrucklaneClick = () => {
    if (chevronDirection === 'down') {
      event(`OC - Trucklane Info Expanded`, {
        userId: user._id,
        offerId: offerInfo._id,
      });
      setChevronDirection('up');
    }
    if (chevronDirection === 'up') setChevronDirection('down');
  };

  const getTotalPallets = () => {
    if (isAwarded) return activeTruckLane?.totalPallets;
    return (activeTruckLane?.totalPallets || 0) + quantity / offerInfo.inventory.casesPerPallet;
  };

  const getLaneCostCell = (value) => {
    if (value > 0) {
      return <span className={classes.trucklaneCellNegative}>({accounting.formatMoney(value)})</span>;
    }
    return <span className={classes.trucklaneCell}>{accounting.formatMoney(value)}</span>;
  };

  const getNetRevenueCell = (value) => {
    if (value < 0) {
      return <span className={classes.trucklaneCellNegative}>({accounting.formatMoney(-1 * value)})</span>;
    }
    return <span className={classes.trucklaneCell}>{accounting.formatMoney(value)}</span>;
  };

  const renderExpandedTrucklane = () => (
    <div className={classes.expandedTrucklaneContainer}>
      <div className={classes.firstTrucklaneRow}>
        <span className={classes.trucklaneCell}></span>
        <span className={classes.header}>CURRENTLY</span>
        <span className={classes.header}>THIS OFFER</span>
        <span className={classes.header}>TOTAL</span>
      </div>
      <div className={classes.trucklaneRowWithBorder}>
        <span className={classes.header}>WEIGHT</span>
        <span className={classes.trucklaneCell}>{weightWithUnit(activeTruckLane?.totalWeight, user, true)}</span>
        <span className={classes.trucklaneCell}>{weightWithUnit(offerInfo.inventory.unitGrossWeight * quantity, user, true)}</span>
        <span className={classes.trucklaneCell}>{weightWithUnit(summedWeight, user, true)}</span>
      </div>
      <div className={classes.trucklaneRowWithBorder}>
        <span className={classes.header}>PALLETS</span>
        <span className={classes.trucklaneCell}>{accounting.formatNumber(activeTruckLane?.totalPallets)}</span>
        <span className={classes.trucklaneCell}>{accounting.formatNumber(quantity / offerInfo.inventory.casesPerPallet)}</span>
        <span className={classes.trucklaneCell}>{accounting.formatNumber(getTotalPallets())}</span>
      </div>
      <div className={classes.trucklaneRowWithBorder}>
        <span className={classes.header}>REVENUE</span>
        <span className={classes.trucklaneCell}>{accounting.formatMoney(activeTruckLane?.totalRevenue)}</span>
        <span className={classes.trucklaneCell}>{accounting.formatMoney(quantity * pricePerCase)}</span>
        <span className={classes.trucklaneCell}>{accounting.formatMoney(summedRevenue)}</span>
      </div>
      <div className={classes.trucklaneRowWithBorder}>
        <span className={classes.header}>LANE COST</span>
        {getLaneCostCell(activeTruckLane ? offerInfo.trucklaneCost || 0 : 0)}
        {getLaneCostCell(activeTruckLane ? 0 : offerInfo.trucklaneCost || 0)}
        {getLaneCostCell(offerInfo.trucklaneCost || 0)}
      </div>
      <div className={classes.trucklaneRowNetRevenue}>
        <span className={classes.headerNetRevenue}>NET REVENUE</span>
        {getNetRevenueCell(activeTruckLane?.totalRevenue - (activeTruckLane ? offerInfo.trucklaneCost || 0 : 0))}
        {getNetRevenueCell(quantity * pricePerCase - (activeTruckLane ? 0 : offerInfo.trucklaneCost || 0))}
        {getNetRevenueCell(summedRevenue - (offerInfo.trucklaneCost || 0))}
      </div>
    </div>
  );

  return (
    <>
      <div className={activeTruckLane ? classes.trucklane : classes.inactiveTrucklane} onClick={onTrucklaneClick} data-testid="trucklane-info">
        <span className={classes.trucklaneText}>{`${offerInfo.logisticsTerm?.toUpperCase()} TRUCK LANE`}</span>
        <div className={classes.statusContainer}>
          {offerInfo.trucklaneDistance && (
            <span className={classes.trucklaneDistance} id="trucklaneDistance">
              {accounting.formatNumber(offerInfo.trucklaneDistance)} mi.
            </span>
          )}
          <span className={activeTruckLane ? classes.statusText : classes.inactiveStatusText} id="StatusText">
            {activeTruckLane ? 'ACTIVE' : 'INACTIVE'}
          </span>
          <span className={activeTruckLane ? classes.chevronContainer : classes.inactiveChevronContainer} id="ChevronContainer">
            <ChevronIcon className={classes.chevron} direction={chevronDirection} id="Chevron" />
          </span>
        </div>
      </div>
      {chevronDirection === 'up' && renderExpandedTrucklane()}
    </>
  );
};

TrucklaneDetails.propTypes = {
  activeTruckLane: PropTypes.bool,
  offerInfo: PropTypes.object,
  isAwarded: PropTypes.bool,
  quantity: PropTypes.number,
  user: PropTypes.object,
  pricePerCase: PropTypes.number,
};

export default TrucklaneDetails;
