import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import accounting from 'accounting';
import {
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  Select,
  SelectOption,
  TextInput,
  PricingStrategyAnchorKeysObject,
  AlertInfoIcon,
  Theme as theme,
} from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import { NegotiationStrings } from '../../string-resources';
import NegotiationPricingStrategyAnchor from './negotiation-pricing-strategy-anchors';
import { SetSuggestedNegotiationPriceFromAnchor } from '../../graphql/mutations';
import FeatureFlag from '../../layouts/feature-flag';
import featureFlags from '../../enums/feature-flags';

const styles = {
  form__container: {
    width: '100%',
  },
  modal__field: {
    width: '48%',
  },
  modal__fields: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  infoContainer: {
    width: '652px',
    height: '84px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '8px',
    padding: '12px',
    borderRadius: '4px',
    backgroundColor: theme.teal5,
    margin: '24px 0',
  },
  infoIcon: {
    marginTop: '3px',
    width: '16px',
    fill: theme.teal,
  },
  information: {
    width: '100%',
    fontSize: '14px',
  },
  textArea: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  textAreaHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textAreaLabel: {
    fontSize: '14px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
    marginBottom: '2px',
  },
  characterCount: {
    width: '100%',
    fontSize: '12px',
    textTransform: 'uppercase',
    textAlign: 'right',
    color: ({ limitExceeded }) => (limitExceeded ? theme.red : theme.grey80),
  },
  message: {
    height: '54px',
    padding: '6px 12px',
    borderRadius: '4px',
    border: ({ limitExceeded }) => (limitExceeded ? `solid 1px ${theme.red}` : `solid 1px ${theme.grey30}`),
    outline: 'none',
    resize: 'none',
    '&::placeholder': {
      color: theme.grey30,
    },
    '&:focus': {
      border: ({ limitExceeded }) => (limitExceeded ? `solid 1px ${theme.red}` : `solid 1px ${theme.grey80}`),
    },
  },
};

const NegotiationSuggestedPriceModal = ({ onHide, open, negotiationStagedListingIds, total, allSelected, filters, refetchQueries, user }) => {
  const useStyles = createUseStyles(styles);

  const [setSuggestedNegotiationPriceFromAnchor, { loading }] = useMutation(SetSuggestedNegotiationPriceFromAnchor);
  const [selectedAnchor, setSelectedAnchor] = useState(undefined);
  const [anchorValue, setAnchorValue] = useState(100);
  const [message, setMessage] = useState('');

  const classes = useStyles({ limitExceeded: message.length > 120 });
  const textAreaRef = useRef();

  const hide = (response) => {
    let loggedCount = 0;
    let errorCount = 0;
    if (response.data) {
      const loggedResults = response.data.SetSuggestedNegotiationPriceFromAnchor;
      errorCount = loggedResults.errors.length;
      loggedCount = loggedResults.negotiationStagedListingsCount;
    }
    onHide(errorCount, loggedCount);
  };

  const setSuggestedPrices = (ev) => {
    ev.preventDefault();
    const setPriceVariables = {
      negotiationStagedListingIds,
      allSelected,
      inventoryFilters: filters,
      pricingStrategy: selectedAnchor.value,
      anchorValue,
      reason: message,
    };
    setSuggestedNegotiationPriceFromAnchor({
      variables: setPriceVariables,
      refetchQueries,
    })
      .then(hide)
      .catch(hide);
    setMessage('');
  };

  const renderSelectOptions = () => {
    const selectOptions = [];
    const keys = [...NegotiationPricingStrategyAnchor.keys()];
    keys.forEach((k) => {
      if (k !== 'Manual' && k !== 'Default') {
        selectOptions.push(
          <SelectOption key={k} value={k}>
            {NegotiationPricingStrategyAnchor.get(k)}
          </SelectOption>
        );
      }
    });
    return selectOptions;
  };

  const generateTextInputLabelText = (selectedAnchorValue, currencyType) => {
    if (selectedAnchorValue === PricingStrategyAnchorKeysObject.FlatRate && currencyType) {
      return `${NegotiationStrings.flatRate} (${currencyType})`;
    }
    if (selectedAnchorValue === PricingStrategyAnchorKeysObject.FlatRate) {
      return NegotiationStrings.flatRate;
    }
    return NegotiationStrings.percent;
  };

  const cancel = () => {
    onHide(false);
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };
  const canSubmit = selectedAnchor && anchorValue > 0 && message.length <= 120;
  const inputLabelText = generateTextInputLabelText(selectedAnchor?.value, user.site.currencyType);
  return (
    <Modal onHide={onHide} open={open} closeOnEsc closeOnOutsideClick>
      <form onSubmit={canSubmit ? setSuggestedPrices : null} className={classes.form__container}>
        <ModalContent>
          <h2>{NegotiationStrings.adjustPriceModalTitle}</h2>
          <div className={classes.modal__fields}>
            <div className={classes.modal__field}>
              <Select label={NegotiationStrings.anchor} onChange={(selected) => setSelectedAnchor(selected[0])} selectedItem={selectedAnchor}>
                {renderSelectOptions()}
              </Select>
            </div>
            <div className={classes.modal__field}>
              <TextInput
                type="text"
                labelText={inputLabelText}
                onChange={(digits) => setAnchorValue(accounting.unformat(digits))}
                required
                value={
                  selectedAnchor?.value === PricingStrategyAnchorKeysObject.FlatRate
                    ? `${accounting.formatNumber(anchorValue, 2)}`
                    : `${accounting.formatNumber(anchorValue)}%`
                }
              />
            </div>
          </div>
          <FeatureFlag featureName={featureFlags.negotiationContext} showChildrenWhenEnabled={true}>
            <div className={classes.messageContainer}>
              <div className={classes.infoContainer}>
                <AlertInfoIcon className={classes.infoIcon} />
                <div className={classes.information}>
                  Add a reason below that provides context to your customers on why you are negotiating. This message will be attached to every
                  selected inventory line. We recommend doing this because it can increase the likelihood your customers will increase their bids.
                </div>
              </div>
              <div className={classes.textArea}>
                <div className={classes.textAreaHeader}>
                  <div className={classes.textAreaLabel}>Negotiation message</div>
                  <div className={classes.characterCount}>{`${message?.length || 0} / 120 characters`}</div>
                </div>
                <textarea
                  data-element="text-area"
                  value={message}
                  onChange={handleChange}
                  className={classes.message}
                  ref={textAreaRef}
                  onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                  placeholder="This message will appear in your customer’s negotiation sheet on every selected inventory line."
                ></textarea>
              </div>
            </div>
          </FeatureFlag>
        </ModalContent>
        <ModalFooter>
          <Button type="button" onClick={cancel} secondary disabled={loading}>
            Close
          </Button>
          <Button type="submit" className={classes.submit} disabled={!canSubmit} primary loading={loading} loadingText="Wait...">
            {NegotiationStrings.submit(allSelected ? total : negotiationStagedListingIds.length)}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

NegotiationSuggestedPriceModal.propTypes = {
  classes: PropTypes.object,
  onHide: PropTypes.func,
  open: PropTypes.bool,
  negotiationStagedListingIds: PropTypes.array,
  total: PropTypes.number,
  allSelected: PropTypes.bool,
  filters: PropTypes.object,
  SetSuggestedNegotiationPriceFromAnchor: PropTypes.func,
  refetchQueries: PropTypes.array,
  user: PropTypes.object,
};

export default NegotiationSuggestedPriceModal;
