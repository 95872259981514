export const TrucklaneSortOptions = {
  HIGHEST_NET_REV: 'Highest Net Revenue',
  LOWEST_NET_REV: 'Lowest Net Revenue',
  HIGHEST_REV: 'Highest Revenue',
  LOWEST_REV: 'Lowest Revenue',
  HIGHEST_WEIGHT: 'Highest Weight',
  LOWEST_WEIGHT: 'Lowest Weight',
  HIGHEST_PALLET: 'Highest Pallet',
  LOWEST_PALLET: 'Lowest Pallet',
  HIGHEST_SUGGESTED_NET_REV: 'Highest Suggested Net Revenue',
  LOWEST_SUGGESTED_NET_REV: 'Lowest Suggested Net Revenue',
  HIGHEST_SUGGESTED_REV: 'Highest Suggested Revenue',
  LOWEST_SUGGESTED_REV: 'Lowest Suggested Revenue',
  HIGHEST_SUGGESTED_WEIGHT: 'Highest Suggested Weight',
  LOWEST_SUGGESTED_WEIGHT: 'Lowest Suggested Weight',
  HIGHEST_SUGGESTED_PALLET: 'Highest Suggested Pallet',
  LOWEST_SUGGESTED_PALLET: 'Lowest Suggested Pallet',
};
