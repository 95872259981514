/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import accounting from 'accounting';
import {
  DataTableNaked,
  StatusBadge,
  Search,
  Timing,
  Button,
  AcceptOffersIcon,
  AlertService,
  ButtonBar,
  usePolling,
} from '@spoiler-alert/ui-library';
import FeatureFlag from '../../layouts/feature-flag';
import featureFlags from '../../enums/feature-flags';
import { OfferReviewPollingQuery, AwardSummaryQuery } from '../../graphql/queries';
import { acceptOffer, SendLossEmails } from '../../graphql/mutations';
import { useQuery, useMutation } from '@apollo/client';
import withApolloClient from '../../apollo/withApolloClient';
import deleteCachedFieldsOnUserQuery from '../../apollo/cache-helpers/delete-cached-fields-on-user-query';
import deleteCachedFieldsOnListedInventoriesQuery from '../../apollo/cache-helpers/delete-cached-fields-on-listed-inventories-query';
import styles from './offers-styles';
import { TitleService } from '../../services';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import { Breadcrumbs } from '../../store';
import routePaths from '../../route-paths';
import { weightWithUnit } from '../../utilities/unit-helper';
import OfferReviewShiftCard from '../offer-review/offer-review-shift-card';
import checkFeature from '../../helpers/check-feature-flag';
import SendLossEmailsModal from './send-loss-emails-modal';

import { v4 as uuidv4 } from 'uuid';

const useStyles = createUseStyles(styles);

const EXPORT_STATUS = {
  NOT_IN_PROGRESS: 'NOT_IN_PROGRESS',
  STARTED: 'STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
};

const exportStatusStringMap = {
  IN_PROGRESS: 'ACCEPTANCE IN PROGRESS',
  COMPLETE: 'ACCEPTED',
  NOT_IN_PROGRESS: 'NOT ACCEPTED',
  STARTED: 'PENDING',
};

const UI_LOCK_STATUS = {
  OPEN: 'OPEN',
  LOCKED: 'LOCKED',
};

const OfferReview = ({ user, history }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [exportsStarted, setExportsStarted] = useState(false);
  const [acceptOfferMutation, { loading: accepting }] = useMutation(acceptOffer);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [showSendLossEmailsModal, setShowSendLossEmailsModal] = useState(false);
  const awardSummaryResults = useQuery(AwardSummaryQuery);
  const [sendLossEmails] = useMutation(SendLossEmails);

  const statusPolling = usePolling({
    query: OfferReviewPollingQuery,
    poll: true,
    responseKey: 'offerReviewPollingQuery',
    idleInterval: 10000,
    activeInterval: 1000,
    isInProgress: () => accepting,
  });

  const noOffersToAccept = () =>
    (!statusPolling || statusPolling?.uiLockStatus === UI_LOCK_STATUS.OPEN) &&
    !awardSummaryResults.data?.currentUserQuery.getAwardSummary.results?.filter((result) => {
      return exportStatusStringMap[result.exportStatus] === exportStatusStringMap.NOT_IN_PROGRESS;
    }).length;

  const acceptAllDisabled = !(statusPolling?.uiLockStatus !== UI_LOCK_STATUS.LOCKED) || accepting || exportInProgress || noOffersToAccept();

  const statusBadgeByBuyerMap = useMemo(() => {
    const statusMap = new Map();
    statusPolling?.exportStatuses.forEach((e) => {
      const statusBadgeKey = exportStatusStringMap[e.exportStatus];
      statusMap.set(e.buyerSiteName, statusBadgeKey);
    });
    return statusMap;
  }, [statusPolling]);

  useEffect(() => {
    let inProgress = false;
    if (awardSummaryResults.data?.currentUserQuery.getAwardSummary.timestamp > statusPolling?.timestamp) {
      awardSummaryResults.data?.currentUserQuery.getAwardSummary.results?.forEach((result) => {
        if (exportStatusStringMap[result.exportStatus] === exportStatusStringMap.IN_PROGRESS) inProgress = true;
      });
    } else {
      statusPolling?.exportStatuses.forEach((e) => {
        if (exportStatusStringMap[e.exportStatus] === exportStatusStringMap.IN_PROGRESS) inProgress = true;
      });
    }
    setExportInProgress(inProgress);
  }, [awardSummaryResults.data, statusPolling]);

  useEffect(() => {
    TitleService.setTitles('Offer Review');
    Breadcrumbs.set([
      {
        url: routePaths.review,
        title: 'Offer Review',
      },
    ]);
  }, []);

  const acceptAllCompleteMessage = () => {
    const message = 'All offers have successfully been accepted';
    AlertService.alert({ type: 'success', message: <span>{message}</span>, autoDismiss: true, dismissDelay: 3000 });
  };

  const completeExports = () => {
    acceptAllCompleteMessage();
    setExportsStarted(false);
    awardSummaryResults.refetch();
  };

  const exportsComplete = () =>
    !awardSummaryResults.data?.currentUserQuery.getAwardSummary.results.length &&
    statusPolling?.uiLockStatus === UI_LOCK_STATUS.OPEN &&
    exportsStarted;

  useEffect(() => {
    if (exportsComplete()) {
      completeExports();
    }
  }, [awardSummaryResults.data]);

  const columnOverrides = () => {
    return {
      transactionLocationName: {
        groupHeader: (key, rows) => {
          let status = statusBadgeByBuyerMap.get(key);
          if (status === exportStatusStringMap[EXPORT_STATUS.NOT_IN_PROGRESS]) {
            rows.forEach((row) => {
              if (row.exportStatus !== EXPORT_STATUS.NOT_IN_PROGRESS) {
                status = exportStatusStringMap[row.exportStatus];
              }
            });
          }
          return (
            <div className={classes.badge__wrapper}>
              {key}{' '}
              <div className={classes.badge__pending}>
                <StatusBadge status={status || exportStatusStringMap[EXPORT_STATUS.NOT_IN_PROGRESS]} key="status" />
              </div>
            </div>
          );
        },
      },
      poundsAwarded: {
        groupSummary: (rows) => {
          const value = rows.reduce((sum, row) => sum + row.poundsAwarded, 0);
          return weightWithUnit(value, user);
        },
      },
      acceptedOffer: {
        groupSummary: (rows) => accounting.formatMoney(rows.reduce((sum, row) => sum + row.acceptedOffer, 0)),
      },
      palletsAwarded: {
        groupSummary: (rows) =>
          accounting.formatNumber(
            rows.reduce((sum, row) => sum + row.palletsAwarded, 0),
            2
          ),
      },
      costRecoveryRate: {
        groupSummary: (rows) => {
          const totalCost = rows.reduce((sum, row) => sum + row.totalCost, 0);
          const acceptedOffer = rows.reduce((sum, row) => sum + row.acceptedOffer, 0);
          return `${((acceptedOffer / totalCost) * 100).toFixed(2)}%`;
        },
      },
      totalPrice: {
        groupSummary: (rows) => accounting.formatMoney(rows.reduce((sum, row) => sum + row.totalPrice, 0)),
      },
      casesAwarded: {
        groupSummary: (rows) =>
          accounting.formatNumber(
            rows.reduce((sum, row) => sum + row.casesAwarded, 0),
            0
          ),
      },
      discountPercent: {
        groupSummary: (rows) => {
          const totalPrice = rows.reduce((sum, row) => sum + row.totalPrice, 0);
          const acceptedOffer = rows.reduce((sum, row) => sum + row.acceptedOffer, 0);
          const result = acceptedOffer && totalPrice ? ((1 - acceptedOffer / totalPrice) * 100).toFixed(2) : undefined;
          return isNaN(result) ? undefined : `${result}%`;
        },
      },
      writeOffDiscountPercent: {
        groupSummary: (rows) => {
          const acceptedOffer = rows.reduce((sum, row) => sum + row.acceptedOffer, 0);
          const writeOffPriceOfInventoryAwarded = rows.reduce((sum, row) => sum + row.writeOffPriceOfInventoryAwarded, 0);
          const result =
            acceptedOffer && writeOffPriceOfInventoryAwarded ? ((1 - acceptedOffer / writeOffPriceOfInventoryAwarded) * 100).toFixed(2) : undefined;
          return isNaN(result) ? undefined : `${result}%`;
        },
      },
      unitPriceRecoveryRate: {
        groupSummary: (rows) => {
          const acceptedOffer = rows.reduce((sum, row) => sum + row.acceptedOffer, 0);
          const unitPriceRecoveryRate = rows.reduce((sum, row) => sum + row.unitPriceRecoveryRate, 0);
          const result = acceptedOffer && unitPriceRecoveryRate ? ((acceptedOffer / unitPriceRecoveryRate) * 100).toFixed(2) : undefined;
          return isNaN(result) ? undefined : `${result}%`;
        },
      },
    };
  };

  const columns = () => [
    ...getColumnsFromDataTableProfile('Offer Comp Buyer Flyup and Offer Review', user.site.dataTableProfiles, columnOverrides()),
  ];

  const error = () => {
    AlertService.alert({
      type: 'warning',
      message: (
        <span>
          {'Sorry there was an error accepting all offers. If this problem persists, please contact a Spoiler Alert Administrator to help you.'}
        </span>
      ),
    });
  };

  const handleDynamicRowClick = (row) => {
    const status = statusBadgeByBuyerMap.get(row.siteName);
    if (status !== exportStatusStringMap[EXPORT_STATUS.NOT_IN_PROGRESS] || row.exportStatus !== EXPORT_STATUS.NOT_IN_PROGRESS) return;
    history.push(`${routePaths.offerReview}/${row.siteId}/${encodeURIComponent(row.siteName)}`);
  };

  const handleSearch = Timing.debounce((searchText) => setSearchText(searchText), 300);

  const buyerList = useMemo(() => {
    if (!awardSummaryResults.data) return [];
    return awardSummaryResults?.data?.currentUserQuery.getAwardSummary.results.map((buyer) => ({
      ...buyer,
      _id: `${buyer.transactionLocationName}-${buyer.siteName}`,
    }));
  }, [awardSummaryResults.data?.currentUserQuery]);

  const handleAcceptAll = Timing.debounce(async () => {
    setExportsStarted(true);
    const buyerSiteIds = buyerList.map((b) => b.siteId);
    const { data } = await acceptOfferMutation({
      variables: { buyerSiteIds, acceptAll: true },
      update: (cache) => {
        deleteCachedFieldsOnUserQuery(cache, ['awardedOfferListings', 'awardedInventoryFilterParameters', 'getAwardSummary']);
        deleteCachedFieldsOnListedInventoriesQuery(cache);
      },
    });

    if (data.acceptOffer.errors.length) {
      error();
      awardSummaryResults.refetch();
    }
  }, 1000);

  const handleDonatingToggleClick = () => history.push(routePaths.donationReview);

  const onSendLossEmailsSubmit = async (storefrontName, dropDate) => {
    const { data } = await sendLossEmails({
      variables: { storefrontName, dropDate },
    });

    if (data.sendLossEmails.errors.length) {
      AlertService.alert({
        type: 'warning',
        message: (
          <span>
            {'Sorry there was an error sending loss emails. If this problem persists, please contact a Spoiler Alert Administrator to help you.'}
          </span>
        ),
      });
    } else {
      AlertService.alert({
        type: 'success',
        message: <span>{'Loss emails sent successfully'}</span>,
      });
    }
  };

  return (
    <div id="offers">
      <div className={classes.actionBar}>
        <Search onChange={handleSearch} className={classes.offerReviewSearch} />
        {checkFeature(featureFlags.marketplace) && (
          <Button secondary onClick={() => setShowSendLossEmailsModal(true)}>
            Send Loss Emails
          </Button>
        )}
        <Button
          classes={{ iconHolder: classes.buttonIcon }}
          primary
          disabled={acceptAllDisabled}
          loading={accepting}
          onClick={handleAcceptAll}
          icon={AcceptOffersIcon}
          loadingText="Accepting All Offers"
          cypressTag="accept-all-offers-button"
        >
          Accept All Offers
        </Button>
      </div>
      <div className={classes.table_wrap}>
        <DataTableNaked
          data={buyerList}
          loading={awardSummaryResults.loading}
          columns={columns()}
          searchText={searchText}
          onRowClick={handleDynamicRowClick}
          groupBy={(row) => row.siteName}
          sticky
          cypressTagTable="offer-review-table"
        />
      </div>{' '}
      <SendLossEmailsModal onHide={() => setShowSendLossEmailsModal(false)} open={showSendLossEmailsModal} onSubmit={onSendLossEmailsSubmit} />
    </div>
  );
};

OfferReview.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
  history: PropTypes.object,
  apolloClient: PropTypes.object,
};

const ComponentWithApolloClient = withApolloClient(OfferReview);
export default ComponentWithApolloClient;
