import React, { useRef, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Theme as theme, MessageAddIcon, MessageAddedIcon, MiniTooltip, ClickAwayListener, AlertService } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import { SetNegotiationReason } from '../../graphql/mutations';
import { StagedNegotiationSummaryQuery } from '../../graphql/queries';

const styles = {
  cellContainer: {
    position: 'relative',
    margin: '-4px 0 -10px 0',
  },
  rowButton: {
    right: '-10px',
    position: 'relative',
  },
  textAreaContainer: {
    position: 'absolute',
    display: 'flex',
    top: '-1px',
    right: '-8px',
    width: '483px',
    height: '147px',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: '16px',
    borderRadius: '2px',
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.05)',
    border: `solid 1px ${theme.borderColor}`,
    backgroundColor: theme.white,
  },
  characterCount: {
    width: '100%',
    fontSize: '12px',
    textTransform: 'uppercase',
    textAlign: 'right',
    color: ({ limitExceeded }) => (limitExceeded ? theme.red : theme.grey80),
  },
  messageInput: {
    width: '451px',
    height: '69px',
    margin: '4px 0 0',
    padding: '13px 12px 16px 16px',
    borderRadius: '2px',
    border: ({ limitExceeded }) => (limitExceeded ? `solid 1px ${theme.red}` : `solid 1px ${theme.grey30}`),
    outline: 'none',
    resize: 'none',
    backgroundColor: theme.white,
  },
  saveButton: {
    textAlign: 'right',
    backgroundColor: 'transparent',
    color: theme.teal,
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    padding: '0',
    fontSize: '12px',
    marginTop: '12px',
    '&[disabled]': {
      color: ({ isSaved }) => (isSaved ? theme.teal : theme.grey30),
      cursor: 'not-allowed',
    },
  },
};

const NegotiationMessage = ({ row, hovered }) => {
  const useStyles = createUseStyles(styles);

  const textAreaRef = useRef();

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [setNegotiationReason, { loading }] = useMutation(SetNegotiationReason);
  const [isSaved, setIsSaved] = useState(false);
  const [touched, setTouched] = useState(false);

  const classes = useStyles({ hovered, limitExceeded: message?.length > 120, isSaved });

  useEffect(() => {
    if (showMessage) textAreaRef.current.focus();
  }, [showMessage]);

  useEffect(() => {
    setMessage(row.negotiationListing?.reason || '');
  }, [row.negotiationListing?.reason]);

  const handleChange = (e) => {
    setTouched(true);
    setIsSaved(false);
    setMessage(e.target.value);
  };

  const saveMessage = () => {
    if (isSaved) return;
    setShowMessage(true);
    setNegotiationReason({
      variables: {
        negotiationStagedListingIds: [row.negotiationListing._id],
        negotiationListingIds: [row.offerListing.negotiationListingId],
        reason: message,
      },
      refetchQueries: [{ query: StagedNegotiationSummaryQuery }],
    }).then((response) => {
      if (response.data.setNegotiationReason.errors.length > 0) {
        AlertService.alert({ type: 'warning', message: <span>{response.data.setNegotiationReason.errors[0].message}</span> });
        setIsSaved(false);
      } else {
        setTouched(false);
        setIsSaved(true);
        setMessage(response.data.setNegotiationReason.reason);
      }
    });
  };

  const colapseForm = () => {
    setShowMessage(false);
    setIsSaved(false);
  };

  const buttonText = () => {
    if (loading) {
      return 'Saving';
    }
    if (isSaved && !touched) {
      return 'Saved!';
    }
    return 'Save';
  };

  const handleShowMessage = () => {
    setShowMessage(true);
  };

  const getIcon = () => {
    if (message) {
      return MessageAddedIcon;
    }
    return MessageAddIcon;
  };

  return (
    <div className={classes.cellContainer}>
      <MiniTooltip text={message ? 'Edit message' : 'Add message'}>
        <Button className={classes.rowButton} onClick={handleShowMessage} icon={getIcon()} resting={!hovered}></Button>
      </MiniTooltip>
      {showMessage && (
        <ClickAwayListener onClickAway={colapseForm}>
          <div className={classes.textAreaContainer}>
            <div className={classes.characterCount}>{`${message?.length || 0} / 120 characters`}</div>
            <textarea
              name="negotiation-message"
              data-element="text-area"
              value={message}
              onChange={handleChange}
              className={classes.messageInput}
              ref={textAreaRef}
              onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
            ></textarea>
            <button type="submit" className={classes.saveButton} onClick={(e) => saveMessage(e)} disabled={!touched || message?.length > 120}>
              {buttonText()}
            </button>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

NegotiationMessage.propTypes = {
  row: PropTypes.object,
  hovered: PropTypes.bool,
};
export default NegotiationMessage;
