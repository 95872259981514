import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Modal, ModalContent, ModalFooter, Button, Select, SelectOption, MinimalTextTable, Theme } from '@spoiler-alert/ui-library';
import { LossEmailsQuery, StorefrontsQuery, DropDatesQuery } from '../../graphql/queries';

const useStyles = createUseStyles({
  form__container: {
    width: '100%',
    '@media (max-width: 450px)': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  modalInfo: {
    fontSize: 16,
    '@media (max-width: 450px)': {
      fontSize: 14,
    },
    marginBottom: 24,
  },
  modalHeader: {
    fontSize: 24,
    '@media (max-width: 450px)': {
      fontSize: 18,
    },
  },
  modalInput: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    marginBottom: 24,
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    gap: 24,
    marginBottom: 24,
  },
  select: {
    width: '100% !important',
  },
  noBuyers: {
    color: Theme.errorColor,
  },
  emailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
});

const SendLossEmailsModal = ({ onHide, open, onSubmit }) => {
  const classes = useStyles();
  const [storefront, setStorefront] = useState();
  const [dropDate, setDropDate] = useState();
  const [loading, setLoading] = useState(false);
  const { data: storefrontsData } = useQuery(StorefrontsQuery, { skip: !open });
  const { data: dropDatesData } = useQuery(DropDatesQuery, {
    variables: { storefrontName: storefront?.value },
    skip: !storefront,
    fetchPolicy: 'no-cache',
  });
  const { data: lossEmailsData } = useQuery(LossEmailsQuery, {
    variables: { storefrontName: storefront?.value, dropDate: dropDate?.value },
    skip: !(storefront && dropDate),
    fetchPolicy: 'no-cache',
  });

  const submitModal = async (event) => {
    event.preventDefault();
    setLoading(true);
    await onSubmit(storefront.value, dropDate.value);
    onHide();
    setLoading(false);
    setStorefront();
    setDropDate();
  };

  const onCancelClick = () => {
    onHide();
    setStorefront();
    setDropDate();
  };

  const onStorefrontChange = (newStorefront) => {
    setDropDate();
    setStorefront(newStorefront[0]);
  };

  return (
    <Modal onHide={() => onHide} open={open} closeOnEsc closeOnOutsideClick>
      <form onSubmit={submitModal} className={classes.form__container}>
        <ModalContent>
          <h2 className={classes.modalHeader}>Submit Offer</h2>
          <div>
            <div className={classes.modalInfo}>
              <span>
                Chose the storefront and drop cycle date you would like to send loss emails for. All customers that have not been awarded inventory
                will be sent an email.
              </span>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.select}>
                <Select label="Storefront" onChange={onStorefrontChange} selectedItem={storefront || {}}>
                  {storefrontsData?.storefrontsQuery?.storefronts.map((sf, index) => (
                    <SelectOption key={index} value={sf} selected={storefront?.value === sf}>
                      {sf}
                    </SelectOption>
                  ))}
                </Select>
              </div>
              <div className={classes.select}>
                <Select
                  label="Drop cycle date"
                  onChange={(newDropDate) => setDropDate(newDropDate[0])}
                  selectedItem={dropDate || {}}
                  disabled={!storefront}
                >
                  {dropDatesData?.dropDatesQuery?.dropDates.map((date, index) => (
                    <SelectOption key={index} value={date} selected={dropDate?.value === date}>
                      {date}
                    </SelectOption>
                  ))}
                </Select>
              </div>
            </div>
            {dropDate?.value && lossEmailsData && !!lossEmailsData.lossEmailsQuery.buyersWithEmails.length && (
              <div className={classes.emailsContainer}>
                <span>Emails will be sent to:</span>
                <MinimalTextTable
                  keyHeader="Customer"
                  possibleValues={['Email']}
                  data={lossEmailsData.lossEmailsQuery.buyersWithEmails.map((lossEmail) => {
                    return { key: lossEmail.buyerName, value: lossEmail.emails };
                  })}
                />
              </div>
            )}
            {dropDate?.value && lossEmailsData && !lossEmailsData.lossEmailsQuery.buyersWithEmails.length && (
              <div className={classes.emailsContainer}>
                <span>Emails will be sent to:</span>
                <span className={classes.noBuyers}>No buyer organizations match your selection.</span>
              </div>
            )}
          </div>
        </ModalContent>
        <ModalFooter>
          <Button type="button" onClick={onCancelClick} secondary>
            Cancel
          </Button>
          <Button
            type="submit"
            className={classes.submit}
            primary
            disabled={!lossEmailsData?.lossEmailsQuery?.buyersWithEmails?.length}
            loading={loading}
            loadingText="Sending Emails..."
          >
            Send Emails
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

SendLossEmailsModal.propTypes = {
  onHide: PropTypes.func,
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  sellerSiteName: PropTypes.string,
};

export default SendLossEmailsModal;
