import { Theme as theme } from '@spoiler-alert/ui-library';

const styles = {
  quantity: {
    textDecoration: `underline dashed ${theme.pillFontColor}`,
    cursor: 'pointer',
  },
  select__box: {
    order: 0,
    marginRight: '12px !important',
  },
  search: {
    order: 2,
    marginLeft: 'auto',
  },
  search__row: {
    justifyContent: 'flex-start',
  },
  filter__button: {
    order: 3,
  },
  negotiations__wrap: {
    marginBottom: 20,
  },
  'custom-content__container': {
    display: 'flex',
    alignItems: 'center',
  },
  'bulk-action__buttons': {
    order: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 5,
  },
};

export default styles;
